import { LessonDetails } from '../../../models/lessonDetails';

import { LESSON_STATUS } from '../../../consts/lessons';
import { FlowOption, FLOW_TYPES } from '../../../consts/newLessonForm';

export const switchInitialValues = (flowType: FlowOption | string): Partial<LessonDetails> | undefined => {
  switch (flowType) {
    case FLOW_TYPES.PROJECT:
      return {
        actionTaker: {
          id: '',
        },
        actionTakerDeputy: {
          id: '',
        },
        additionalInformation: '',
        attachments: [],
        createDate: '',
        description: '',
        filesUpload: [],
        id: '',
        improvementDelivered: false,
        organizationId: '',
        origin: FLOW_TYPES.PROJECT,
        projectId: '',
        projectName: '',
        projectTypeId: '',
        recommendation: '',
        state: LESSON_STATUS.TO_DO,
        tgPhase: [],
        title: '',
        workstreamId: '',
        workstreamName: '',
      };
    case FLOW_TYPES.OPERATING_SITE:
      return {
        additionalInformation: '',
        attachments: [],
        createDate: '',
        description: '',
        id: '',
        improvementDelivered: false,
        origin: FLOW_TYPES.OPERATING_SITE,
        organizationId: '',
        operatingSiteId: '',
        projectTypeId: '',
        recommendation: '',
        state: LESSON_STATUS.TO_DO,
        title: '',
        workstreamId: '',
        workstreamName: '',
        actionTaker: {
          id: '',
        },
        actionTakerDeputy: {
          id: '',
        },
        filesUpload: [],
      };
    case FLOW_TYPES.ORGANIZATIONAL_UNIT:
      return {
        additionalInformation: '',
        attachments: [],
        createDate: '',
        description: '',
        id: '',
        improvementDelivered: false,

        origin: FLOW_TYPES.ORGANIZATIONAL_UNIT,
        organizationId: '',
        departmentId: '',
        departmentName: '',
        recommendation: '',
        state: LESSON_STATUS.TO_DO,
        title: '',
        workstreamId: '',
        workstreamName: '',
        actionTaker: {
          id: '',
        },
        actionTakerDeputy: {
          id: '',
        },
        filesUpload: [],
      };
  }
};
