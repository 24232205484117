import { FC } from 'react';

import { WorkshopDetails } from '../components/Workshop';
import { Home, InfoElements, LessonsList, Login, NewLessonPage, Page404, Reports, TagEditor, Welcome, Workshops } from '../pages';
import LessonsWithNoActiveActionTaker from '../pages/Admin/LessonsWithNoActiveActionTaker';

export interface LLPRoute {
  component: FC;
  exact: boolean;
  hideInNav: boolean;
  id: string;
  name: string;
  needsAdmin: boolean;
  private: boolean;
  url: string;
  withLayout: boolean;
}

const routes: LLPRoute[] = [
  {
    component: Welcome,
    exact: true,
    hideInNav: true,
    id: 'welcome',
    name: 'Welcome',
    needsAdmin: false,
    private: false,
    url: '/',
    withLayout: false,
  },
  {
    component: Home,
    exact: true,
    hideInNav: false,
    id: 'home',
    name: 'Home',
    needsAdmin: false,
    private: true,
    url: '/home',
    withLayout: false,
  },
  {
    component: Login,
    exact: false,
    hideInNav: true,
    id: 'login',
    name: 'Login',
    needsAdmin: false,
    private: false,
    url: '/login',
    withLayout: false,
  },
  {
    component: NewLessonPage,
    exact: true,
    hideInNav: false,
    id: 'new-lesson',
    name: 'Add Lesson',
    needsAdmin: false,
    private: true,
    url: '/new-lesson',
    withLayout: false,
  },
  {
    component: LessonsList,
    exact: true,
    hideInNav: false,
    id: 'take-action-list',
    name: 'Take Action',
    needsAdmin: false,
    private: true,
    url: '/lessons/:tabName/:lessonId?',
    withLayout: false,
  },
  {
    component: LessonsList,
    exact: true,
    hideInNav: false,
    id: 'my-lessons-list',
    name: 'My Submitted Lessons',
    needsAdmin: false,
    private: true,
    url: '/lessons/:tabName/:lessonId?',
    withLayout: false,
  },
  {
    component: LessonsList,
    exact: true,
    hideInNav: false,
    id: 'lessons-list',
    name: 'All Lessons',
    needsAdmin: false,
    private: true,
    url: '/lessons/:tabName/:lessonId?',
    withLayout: false,
  },
  {
    component: Workshops,
    exact: true,
    hideInNav: false, // TODO: restore after workshops feature will be finished
    id: 'workshops',
    name: 'Workshops',
    needsAdmin: false,
    private: true,
    url: '/workshops',
    withLayout: false,
  },
  {
    component: WorkshopDetails,
    exact: true,
    hideInNav: true,
    id: 'workshops',
    name: 'Workshops',
    needsAdmin: false,
    private: true,
    url: '/workshops/:id',
    withLayout: false,
  },
  {
    component: InfoElements,
    exact: true,
    hideInNav: false,
    id: 'info-elements-admin',
    name: 'Manage info elements',
    needsAdmin: true,
    private: true,
    url: '/info-elements-admin',
    withLayout: false,
  },
  {
    component: LessonsWithNoActiveActionTaker,
    exact: true,
    hideInNav: false,
    id: 'lessons-with-no-active-at-admin',
    name: 'Assign action takers',
    needsAdmin: true,
    private: true,
    url: '/lessons-with-no-active-at-admin',
    withLayout: false,
  },
  {
    component: Reports,
    exact: true,
    hideInNav: false,
    id: 'reports',
    name: 'Reports',
    needsAdmin: false,
    private: true,
    url: '/reports',
    withLayout: false,
  },
  {
    component: TagEditor,
    exact: true,
    hideInNav: false,
    id: 'tags-editor',
    name: 'Create & Edit Tags',
    needsAdmin: true,
    private: true,
    url: '/tags/:category?',
    withLayout: false,
  },
  {
    component: Page404,
    exact: false,
    hideInNav: true,
    id: '404',
    name: '404',
    needsAdmin: false,
    private: false,
    url: '*',
    withLayout: false,
  },
];

export default routes;
