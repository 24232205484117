import { FileWithPath } from 'react-dropzone';

import { AxiosError } from 'axios';
import { compare } from 'fast-json-patch';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAdvancedFilters, IFilterSettings, IFilterStructures } from '../models/filterSettings';
import { Lesson } from '../models/lesson';
import { LessonDetails } from '../models/lessonDetails';
import { Tag } from '../models/tags';
import { ADUser } from '../models/user';

import { handleApiError, removeEmptyFromObject } from '../utils/functions';

import { ReduxStoreType } from '.';
import { FIELDS_NAMES_ONLY } from '../components/LessonsFilters/consts';
import { NOTIFICATION_TYPES } from '../components/vfDesign/vfNotification';
import config from '../config';
import { CREATE_DATE_TYPES, CreateDateOption, STATE_TAGNAMES } from '../consts/lessons';
import { FlowOption } from '../consts/newLessonForm';
import Api from '../services/api';
import { getToken } from '../services/auth';
import { uploadAttachments } from './attachments';
import { toggleFavoriteInLessonDetails } from './lessonsDetails';
import { setNotification } from './notifications';

export const sliceName = 'lessons';

export type LessonsFilteredResponse = {
  content: Lesson[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  sortColumns: string[];
  totalElements: number;
  totalPages: number;
  workshopId?: string;
  workshopName?: string;
};

export interface LessonOverviewResponse {
  title: string;
  id: string;
}

export interface LessonsState {
  data: Lesson[];
  isLoading: boolean;
  page: number;
  totalPages: number;
  restartPages: boolean;
  allLessonsOverview: {
    data: LessonOverviewResponse[];
    isLoading: boolean;
  };
}

export interface LessonCreateParams {
  lesson: ILessonExtendedProps;
  flowType: FlowOption | null;
}

export interface ILessonFavoriteToggle {
  lessonId: string;
}

export interface ILessonReportPayload {
  lessonIds: string[];
  exportActionTakerInfo: boolean;
  exportCategorizationInfo: boolean;
  exportStatusDetailsInfo: boolean;
}

export interface ILessonExtendedProps {
  actionTakerDeputyId: string;
  actionTakerId: string;
  additionalInformation: string;
  departmentId: string;
  deputy: Partial<ADUser>;
  description: string;
  files: FileWithPath[];
  operatingSiteId: string;
  organizationId: string;
  origin: FlowOption | null;
  owner: Partial<ADUser>;
  priority: string;
  priorityJustification?: string;
  processId: string;
  projectId: string;
  projectTypeId: string;
  recommendation: string;
  tags: Tag[];
  tgPhase: string[];
  title: string;
  workstreamId: string;
}

export interface FetchLessonBody {
  [field: string]: any;

  actionTakerDeputyId?: string;
  actionTakerDeputyName?: string;
  actionTakerId?: string;
  actionTakerName?: string;
  addedByUser?: boolean;
  advancedFilters?: IAdvancedFilters;
  createDateLimitation?: CreateDateOption | '';
  creatorId?: string;
  creatorName?: string;
  departmentId?: string;
  departmentName?: string;
  favourites?: boolean | string;
  improvementDelivered?: string | boolean;
  olc?: string;
  operatingSiteId?: string;
  operatingSiteName?: string;
  organizationId?: string;
  organizationName?: string;
  origin: FlowOption | '';
  page: number;
  pageSize: number;
  afterDueDate?: boolean | string;
  poked?: boolean | string;
  delayed?: boolean | string;
  priority?: string;
  processId?: string;
  processName?: string;
  projectId?: string;
  projectName?: string;
  projectTypeId?: string;
  projectTypeName?: string;
  recommendation?: string;
  state?: string;
  states?: string[];
  structures?: IFilterStructures;
  tabName?: string;
  tgPhase?: string[];
  title: string;
  userIsResponsible?: boolean;
  workstreamId?: string;
  workstreamName?: string;
}

const no_tab_error = 'no_tab_error';

const getBodyWithoutEmptyValues = (body: IFilterSettings) => {
  const payload: FetchLessonBody = { ...body, ...body.advancedFilters };

  delete payload?.advancedFilters;
  delete payload?.structures;

  FIELDS_NAMES_ONLY.forEach((fieldToDelete) => {
    delete payload[fieldToDelete];
  });

  delete payload.customDate;

  if (!payload.tabName) {
    // eslint-disable-next-line no-throw-literal
    throw no_tab_error;
  }

  if (payload.improvementDelivered) {
    payload.improvementDelivered = true;
  }

  if (payload.favourites) {
    payload.favourites = true;
  }

  if (payload.afterDueDate) {
    payload.afterDueDate = true;
  }

  if (payload.delayed) {
    payload.delayed = true;
  }

  if (payload.poked) {
    payload.poked = true;
  }

  if (payload.tabName === STATE_TAGNAMES.TAKE_ACTION) {
    payload.userIsResponsible = true;
  }

  if (payload.tabName === STATE_TAGNAMES.MY_LESSONS) {
    payload.addedByUser = true;
  }

  if (payload.tgPhase?.length === 0) {
    delete payload.tgPhase;
  }

  if (payload.createDateLimitation !== CREATE_DATE_TYPES.CUSTOM_DATE) {
    delete payload.startDate;
    delete payload.endDate;
  }

  if (payload.tags) {
    payload.tagIds = payload.tags.map((t: Tag) => t.id);

    delete payload.tags;
  }

  delete payload.tabName;

  return removeEmptyFromObject<FetchLessonBody>(payload);
};

export const fetchLessons = createAsyncThunk(`${sliceName}/fetch`, async (body: IFilterSettings, { dispatch }) => {
  try {
    const bodyWithoutEmptyValues = getBodyWithoutEmptyValues(body);

    const response = await Api.post<LessonsFilteredResponse>('/lessons/filter', bodyWithoutEmptyValues);

    return response.data;
  } catch (err) {
    if (err !== no_tab_error) {
      // TODO: temp workaround, no tabName issue
      handleApiError(err as AxiosError, 'We could not fetch lessons', dispatch);
    }

    throw err;
  }
});

export const fetchAllLessonsOverview = createAsyncThunk(
  `${sliceName}/overview`,
  async (body: IFilterSettings, { dispatch }) => {
    try {
      const bodyWithoutEmptyValues = getBodyWithoutEmptyValues(body);
      const response = await Api.post<LessonOverviewResponse[]>('/lessons/filter/overview', bodyWithoutEmptyValues);

      return response.data;
    } catch (err) {
      if (err !== no_tab_error) {
        // TODO: temp workaround, no tabName issue
        handleApiError(err as AxiosError, 'We could not fetch lesson ids', dispatch);
      }

      throw err;
    }
  },
);

interface LessonCreationParams {
  actionTakerDeputyId?: string;
  actionTakerId?: string;
  additionalInformation?: string;
  departmentId?: string;
  description?: string;
  operatingSiteId?: string;
  organizationId?: string;
  origin: FlowOption | null;
  priority: string;
  priorityJustification?: string;
  processId?: string;
  projectId?: string;
  projectTypeId?: string;
  recommendation?: string;
  selectedTagIds: string[];
  tgPhase: string[];
  title?: string;
  workstreamId?: string;
}

export const addLesson = createAsyncThunk(
  `${sliceName}/add`,
  async ({ lesson, flowType }: LessonCreateParams, { dispatch, getState }) => {
    try {
      const body: LessonCreationParams = {
        actionTakerDeputyId: lesson.deputy?.id,
        actionTakerId: lesson.owner?.id,
        additionalInformation: lesson.additionalInformation,
        departmentId: lesson.departmentId,
        description: lesson.description,
        operatingSiteId: lesson.operatingSiteId,
        organizationId: lesson.organizationId,
        origin: flowType,
        priority: lesson.priority,
        priorityJustification: lesson.priorityJustification,
        processId: lesson.processId,
        projectId: lesson.projectId,
        projectTypeId: lesson.projectTypeId,
        recommendation: lesson.recommendation,
        selectedTagIds: lesson.tags.map((t) => t.id),
        tgPhase: lesson.tgPhase,
        title: lesson.title,
        workstreamId: lesson.workstreamId,
      };

      const bodyWithoutEmptyValues = removeEmptyFromObject<LessonCreationParams>(body);

      const response = await Api.post<LessonDetails>('/lessons', bodyWithoutEmptyValues);

      const { id } = response.data;

      if (id && lesson.files?.length) {
        dispatch(uploadAttachments({ files: lesson.files, id }));
      }

      dispatch(setNotification('Lesson was successfully added', NOTIFICATION_TYPES.SUCCESS));

      const state = getState() as ReduxStoreType;

      if (state.lessons.data) {
        dispatch(setLesson(response.data as LessonDetails));
      }

      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not add lesson', dispatch);

      throw err;
    }
  },
);

export const triggerActionTaker = createAsyncThunk(
  `${sliceName}/trigger-action-taker`,
  async ({ lessonId }: { lessonId: string }, { dispatch, getState }) => {
    try {
      const state = getState() as ReduxStoreType;

      await Api.post(`/lessons/${lessonId}/request-action`, {});

      const lesson = state.lessons.data?.find((l) => l.id === lessonId);
      dispatch(updateLesson({ ...lesson, lastActionRequestTime: Date.now() }));
      dispatch(setNotification('Action taker was successfully triggered', NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not trigger action taker', dispatch);

      throw err;
    }
  },
);

export const toggleFavourite = createAsyncThunk(
  `${sliceName}/set-favorite`,
  async ({ lessonId }: ILessonFavoriteToggle, { dispatch, getState }) => {
    try {
      const state = getState() as ReduxStoreType;

      const lesson = state.lessons.data?.find((l) => l.id === lessonId);
      const lessonToUpdate = { ...lesson, favourite: !lesson?.favourite };
      const jsonPatchData = compare(lesson!, lessonToUpdate);

      const response = await Api.patch<LessonDetails>(`/lessons/${lessonId}/favourite`, jsonPatchData, {}, true);

      dispatch(
        setNotification(
          `Lesson was successfully set as ${response.data.favourite ? 'favourite' : 'not favourite'}`,
          NOTIFICATION_TYPES.SUCCESS,
        ),
      );

      dispatch(updateLesson(lessonToUpdate));
      dispatch(toggleFavoriteInLessonDetails());
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not set lesson as favorite', dispatch);

      throw err;
    }
  },
);

export const generateLessonsReport = createAsyncThunk(
  `${sliceName}/generate-report`,
  async (payload: ILessonReportPayload, { dispatch, getState }) => {
    try {
      const token = await getToken();

      const response = await fetch(`${config.API_URL}/lessons/pdf-export`, {
        method: 'POST',

        headers: {
          authorization: `Bearer ${token}`,

          'Content-Type': 'application/json',
        },

        body: JSON.stringify(payload),
      });

      const blob = await response.blob();

      const file = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');

      downloadLink.href = file;
      downloadLink.download = 'lessons.pdf';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not generate report', dispatch);

      throw err;
    }
  },
);

export const initialState: LessonsState = {
  data: [] as Lesson[],
  isLoading: false,
  page: 1,
  totalPages: 2,
  restartPages: false,
  allLessonsOverview: {
    data: [],
    isLoading: false,
  },
};

export const lessonsReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLesson: (state: LessonsState, { payload }) => {
      state.data = [payload, ...state.data];
    },

    updateLesson: (state: LessonsState, { payload }) => {
      const prevIndex = state.data.findIndex((l) => l.id === payload.id);
      const newLessons = [...state.data];
      newLessons[prevIndex] = payload;

      state.data = newLessons;
    },
    clearLessons: (state: LessonsState, { payload }) => {
      state.data = payload;
    },
    setInitialTotalPages: (state: LessonsState) => {
      state.totalPages = initialState.totalPages;
    },
    setRestartPages: (state: LessonsState) => {
      state.restartPages = !state.restartPages;
    },
    removeLesson: (state: LessonsState, { payload }: { payload: string }) => {
      const filteredLessons = state.data.filter((l) => l.id !== payload);

      state.data = filteredLessons;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLessons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLessons.fulfilled, (state, { payload }) => {
      state.data = [...new Set([...state.data, ...payload.content])];
      state.totalPages = payload.totalPages;
      state.isLoading = false;
    });
    builder.addCase(fetchLessons.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(toggleFavourite.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(toggleFavourite.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(toggleFavourite.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllLessonsOverview.pending, (state) => {
      state.allLessonsOverview.isLoading = true;
    });
    builder.addCase(fetchAllLessonsOverview.fulfilled, (state, { payload }) => {
      state.allLessonsOverview.data = payload;
      state.allLessonsOverview.isLoading = false;
    });
    builder.addCase(fetchAllLessonsOverview.rejected, (state) => {
      state.allLessonsOverview.isLoading = false;
    });
  },
});

export const getLessons = (state: ReduxStoreType): LessonsState => state[sliceName];
export const getAllLessonsOverview = (state: ReduxStoreType) => state[sliceName].allLessonsOverview;
export const getTotalPages = (state: ReduxStoreType) => state[sliceName].totalPages;
export const getLessonPages = (state: ReduxStoreType) => state[sliceName].page;
export const getRestartFlag = (state: ReduxStoreType) => state[sliceName].restartPages;

export const { setLesson, updateLesson, clearLessons, setInitialTotalPages, setRestartPages, removeLesson } =
  lessonsReducer.actions;

export default lessonsReducer.reducer;
