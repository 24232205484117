import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import classNames from 'classnames';

import { useMsal } from '@azure/msal-react';

import { getIsFetching } from '../../store/headerLoader';
import { fetchInfoElements, getInfoElements } from '../../store/infoElement';
import { exportData } from '../../store/infoElement';
import { selectMessages } from '../../store/messages';
import { fetchPhases } from '../../store/structure';
import { fetchUserDetails, getUserDetails, getUserPreferences } from '../../store/user';

import { useBoolean, useCookie } from '../../utils/hooks';

import packageJson from '../../../package.json';
import { STATE_TAGNAMES } from '../../consts/lessons';
import { useAppDispatch } from '../../root';
// Routes config
import routes, { LLPRoute } from '../../routes/config';
import Drawer from '../Drawer';
import FeedbackForm, { FEEDBACK_COOKIE } from '../Feedback';
import Tooltip from '../../components/Tooltip';
import IconButton from '../IconButton';
import NotificationCenter from '../NotificationCenter';
import { VfButton, VfIcon, VfLinearProgress, VfPopover } from '../vfDesign';
import Actions from '../vfDesign/vfTable/actions';
import styles from './Header.module.scss';

export interface HeaderProps {
  showProgress?: boolean;
}

const USER_GUIDE_URL = 'https://vattenfall.sharepoint.com/_layouts/15/DocIdRedir.aspx?ID=Q6RKH3Q7KN56-938590189-1';
const USER_GUIDE_WORKSHOP_URL = 'https://vattenfall.sharepoint.com/_layouts/15/DocIdRedir.aspx?ID=Q6RKH3Q7KN56-991786485-16';
const VIDEO_TUTORIAL_URL = 'https://vattenfall.sharepoint.com/_layouts/15/DocIdRedir.aspx?ID=Q6RKH3Q7KN56-991786485-19'

export const USER_GUIDE_OPTIONS = [
  {
    disabled: () => false,
    label: () => 'User Guide',
    method: () => {
      window.open(USER_GUIDE_URL, '_blank', 'fullscreen=yes');
    },
  },
  {
    disabled: () => false,
    label: () => 'User Guide LLP Workshop',
    method: () => {
      window.open(USER_GUIDE_WORKSHOP_URL, '_blank', 'fullscreen=yes');
    },
  },
  {
    disabled: () => false,
    label: () => 'Video tutorials',
    method: () => {
      window.open(VIDEO_TUTORIAL_URL, '_blank');
    },
  },
];

const Header: FC<HeaderProps> = ({ showProgress = true }) => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { data } = useSelector(getUserDetails);

  const { data: infoElementsData } = useSelector(getInfoElements);
  const { feedbackProvided } = useSelector(getUserPreferences);
  const { messages } = useSelector(selectMessages);
  const isFetching = useSelector(getIsFetching);
  const [feedbackTooltipDismissed] = useCookie(FEEDBACK_COOKIE, 'false');
  const [feedbackOpen, openFeedback, closeFeedback] = useBoolean(false);
  const [guideOpen, showGuide, closeGuide] = useBoolean(false);

  // Feedback tooltip and modal
  const showFeedbackTooltip = !feedbackProvided && !JSON.parse(feedbackTooltipDismissed as string);
  const [tooltipType, , setFeedbackFull] = useBoolean(showFeedbackTooltip);

  const [notificationsOpen, setNotificationsOpen, setNotificationsClosed] = useBoolean(false);

  useEffect(() => {
    dispatch(fetchUserDetails());

    if (tooltipType) {
      setTimeout(() => {
        openFeedback();
      }, 1000);
    }

    if (infoElementsData.length === 0) {
      dispatch(fetchInfoElements());
    }

    dispatch(fetchPhases());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { adminAccount } = data || {};

  const handleFeedbackOpen = () => {
    setFeedbackFull();
    openFeedback();
  };

  const handleHomeRedirect = () => {
    history.push('/home');
  };

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleUserClick = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleClosePopover = () => {
    setOpenDropdown(false);
  };

  const handleLogout = () => {
    instance.logout();
  };

  const handleExportData = () => {
    dispatch(exportData());
  };

  const visibleLinks = routes.filter((r) => !r.hideInNav);

  const newNotificationCount = messages.filter && messages.filter((ms) => !ms.markedRead).length;
  const mapLink = (link: LLPRoute) => {
    switch (link.id) {
      case 'lessons-list':
        return `/lessons/${STATE_TAGNAMES.ALL_LESSONS}`;
      case 'take-action-list':
        return `/lessons/${STATE_TAGNAMES.TAKE_ACTION}`;
      case 'my-lessons-list':
        return `/lessons/${STATE_TAGNAMES.MY_LESSONS}`;
      case 'tags-editor':
        return `/tags`;
      default:
        return link.url;
    }
  };

  const onNavLinkClickHandler = () => {
    setOpenMenu(false);
  };

  const links = visibleLinks
    .filter((r) => !r.needsAdmin)
    .map((r) => (
      <NavLink activeClassName="current" key={r.id} to={mapLink(r)} onClick={onNavLinkClickHandler}>
        {r.name}
      </NavLink>
    ));

  const adminLinks = visibleLinks
    .filter((r) => r.needsAdmin)
    .map((r) => (
      <NavLink activeClassName="current" key={r.id} to={mapLink(r)}>
        {r.name}
      </NavLink>
    ));

  const feedbackIconStyles = classNames('mt-1', {
    'icon-vf-feedback--active': feedbackOpen,
  });

  return (
    <>
      <NotificationCenter isOpen={notificationsOpen} onClose={setNotificationsClosed} />
      <Drawer open={openMenu} setOpen={setOpenMenu}>
        {links}

        {adminAccount && (
          <>
            <p>Admin</p>
            {adminLinks}
          </>
        )}
        <VfButton outline="secondary" className="mt-2" onClick={handleExportData}>
          Export Data
        </VfButton>
      </Drawer>
      <div className={styles.header}>
        <IconButton icon="hamburger" onClick={handleMenuClick} />
        <div className={styles['logo-wrapper']}>
          <p className={styles.title} onClick={handleHomeRedirect}>
            Lesson Learned Portal
          </p>
          <div className={styles.logo} onClick={handleHomeRedirect} />

          <div className={styles['right-side']}>

            <div className={styles['feedback-form-wrapper']}>
              {data?.displayName && (
                <FeedbackForm isOpened={feedbackOpen} closeFeedback={closeFeedback} tooltipOnly={tooltipType} />
              )}

              <div className={styles['notification-badge__wrapper']}>
                <Tooltip text="Share your feedback" position="bottom">
                  <VfIcon name="vf-feedback" className={feedbackIconStyles} onClick={handleFeedbackOpen} asButton />
                </Tooltip>
              </div>
            </div>

            <VfPopover
              component={
                <Tooltip text="User guide & video tutorials" position="bottom">
                  <IconButton icon="vf-guide" className={styles['guide-badge']} onClick={showGuide} />
                </Tooltip>
              }
              isOpen={guideOpen}
              closeDropdown={closeGuide}
              className="vf-table__actions"
            >
              <Actions options={USER_GUIDE_OPTIONS} row={{}} />
            </VfPopover>

            <div className={styles['notification-badge__wrapper']}>
              <Tooltip text="Notifications" position="bottom">
                <IconButton icon="bell" onClick={setNotificationsOpen} />
              </Tooltip>
              {newNotificationCount !== 0 && (
                <div className={styles['notification-badge']} data-count={newNotificationCount} />
              )}
            </div>

            <div className={styles['header__user-name']}>{data?.displayName}</div>
          </div>
        </div>

        <VfPopover
          component={<IconButton icon="user" onClick={handleUserClick} />}
          isOpen={openDropdown}
          closeDropdown={handleClosePopover}
          className="vf-table__actions"
          right
        >
          <div>
            <span>Version: {packageJson.version}</span>
            <VfButton onClick={handleLogout} type="primary" className="mt-2">
              Logout
            </VfButton>
          </div>
        </VfPopover>

        {showProgress && <VfLinearProgress isLoading={isFetching} />}
      </div>
    </>
  );
};

export default Header;
