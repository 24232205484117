import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearAdvancedFilters,
  getFilterChips,
  removeAdvancedFilter,
  updateFilterSettings,
} from '../../store/filterSettings';
import { clearLessons, setInitialTotalPages, setRestartPages } from '../../store/lessons';

import { isMobile } from '../../utils/functions';
import useDebouncedValue from '../../utils/hooks/useDebouncedValue';

import { ReportsContext } from '../../context';
import Icon from '../Icon';
import LessonFilters from '../LessonsFilters';
import { DELETE_RELATIONS } from '../LessonsFilters/consts';
import { ReportsDrawer } from '../ReportsDrawer';
import { VfButton, VfChips, VfInput } from '../vfDesign';
import styles from './Searchbar.module.scss';

export interface SearchBarProps {
  placeholder?: string;
}

const SearchBar: FC<SearchBarProps> = () => {
  const dispatch = useDispatch();

  const { isEnabled, toggleSelection, selected, handleClearSelected } = useContext(ReportsContext);

  const chips = useSelector(getFilterChips);
  const [title, setTitle] = useState<string>('');
  const [filtersDrawerIsOpen, setFilterDrawerIsOpen] = useState<boolean>(false);
  const [queryValue, setQuery] = useDebouncedValue(400);
  const [reportsDrawerOpened, setReportsDrawerOpened] = useState(false);
  const { isSelectedAll, handleSelectAll } = useContext(ReportsContext);

  useEffect(() => {
    dispatch(updateFilterSettings({ title: queryValue }));
  }, [dispatch, queryValue]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const newTitle = e?.currentTarget?.value || '';
    dispatch(setRestartPages());
    dispatch(setInitialTotalPages());
    dispatch(clearLessons([]));
    setTitle(newTitle);
    setQuery(newTitle);
  };

  const showFiltersDrawer = (): void => {
    setFilterDrawerIsOpen(true);
  };
  const hideFiltersDrawer = (): void => {
    setFilterDrawerIsOpen(false);
  };
  const onRemove = (id: string): void => {
    dispatch(removeAdvancedFilter(id));
    dispatch(setRestartPages());
    dispatch(setInitialTotalPages());
    dispatch(clearLessons([]));
    if (isSelectedAll) {
      handleSelectAll?.([]);
    }

    if (DELETE_RELATIONS[id]) {
      DELETE_RELATIONS[id].forEach((relatedFilterId: string) => {
        dispatch(removeAdvancedFilter(relatedFilterId));
      });
    }
  };

  const onBlur = () => {};

  const clearAll = (): void => {
    dispatch(setRestartPages());
    dispatch(setInitialTotalPages());
    dispatch(clearLessons([]));
    dispatch(clearAdvancedFilters());
    if (isSelectedAll) {
      handleSelectAll?.([]);
    }
  };

  const placeholder = isMobile() ? 'Find a lesson' : 'Find a lesson (e.g. dry summer months)';

  const handleSelection = () => {
    if (isEnabled) {
      handleClearSelected?.();
    }
    toggleSelection?.();
  };

  const handleCloseDrawer = () => {
    setReportsDrawerOpened(false);
  };

  const handleOpenDrawer = () => {
    setReportsDrawerOpened(true);
  };

  return (
    <>
      <ReportsDrawer isOpen={reportsDrawerOpened} onClose={handleCloseDrawer} />
      <LessonFilters isOpen={filtersDrawerIsOpen} onClose={hideFiltersDrawer} open={filtersDrawerIsOpen} side="right" />
      <div className={styles.searchbar}>
        <div className={styles.searchbar__elements}>
          <VfInput
            icon="search"
            id="searchInput"
            onChange={onChange}
            onBlur={onBlur}
            value={title}
            placeholder={placeholder}
            rounded
          />
          <VfButton outline="secondary" onClick={showFiltersDrawer}>
            <Icon color="blue" name="filter" className="mr-1" />
            Filters
          </VfButton>
          <VfButton outline="secondary" onClick={handleSelection}>
            <Icon color="blue" name="progress--todo" className="mr-1" />
            {isEnabled ? 'Clear' : 'Select'}
          </VfButton>
          {isEnabled && (
            <VfButton outline="secondary" onClick={handleOpenDrawer} disabled={!selected.length}>
              <Icon color="blue" name="file" className="mr-1" />
              Generate report
            </VfButton>
          )}
        </div>
        <div className={styles.searchbar__chips}>
          <VfChips chips={chips} clearAll={clearAll} onRemove={onRemove} />
        </div>
      </div>
    </>
  );
};

export default SearchBar;
