import { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LessonWithNoAT } from '../models/lessonWithNoAT';

import { handleApiError } from '../utils/functions';

import { ReduxStoreType } from '.';
import { IFilterProps } from '../pages/Admin/LessonsWithNoActiveActionTaker/consts';
import Api from '../services/api';

export const sliceName = 'lessonsWithNoAT';

export type LessonsWithNoATResponse = {
  data: LessonWithNoAT[];
};

export interface LessonsWithNoATState {
  lessons: LessonWithNoAT[];
  isLoading: boolean;
  isEditATLoading: boolean;
}

export interface IActionTakerResponse {
  id: string;
  organizationId: string;
  organizationName: string;
  origin: string;
  projectTypeId: string;
  projectTypeName: string;
  departmentId: string;
  departmentName: string;
  projectId: string;
  projectName: string;
  workstreamId: string;
  workstreamName: string;
  processId: string;
  processName: string;
  title: string;
  description: string;
  recommendation: string;
  additionalInformation: string;
  actionTakerAdditionalInformation: string;
  createDate: string;
  state: string;
  stateDetails: string;
  priority: string;
  priorityJustification: string;
  published: boolean;
  favourite: boolean;
  tgPhase: string[];
  lastActionRequestTime: string;
  improvementDelivered: boolean;
  improvementDescription: string;
  attachments: {
    id: string;
    name: string;
  }[];
  creator: {
    id: string;
    displayName: string;
    email: string;
  };
  actionTaker: {
    id: string;
    displayName: string;
    email: string;
  };
  actionTakerDeputy: {
    id: string;
    displayName: string;
    email: string;
  };
  reasonDescription: string;
  operatingSiteId: string;
  operatingSiteName: string;
  userRights: {
    canEditLesson: boolean;
    canTakeActionOnLesson: boolean;
    canPublishLesson: boolean;
    canDeleteLesson: boolean;
  };
  actionTakerSetManually: boolean;
  readyForPublication: boolean;
  workshopId: string;
  workshopName: string;
  positionInWorkshop: number;
  tags: {
    id: string;
    name: string;
    active: boolean;
  }[];
  delayed: boolean;
}

export const fetchLessonsWithNoAT = createAsyncThunk(`${sliceName}`, async (filters: IFilterProps, { dispatch }) => {
  try {
    const response = await Api.post<LessonsWithNoATResponse>('/lessons/with-action-taker-no-longer-working', filters);

    return response.data;
  } catch (err) {
    handleApiError(err as AxiosError, 'We could not fetch lessons', dispatch);

    throw err;
  }
});

export const updateSingleLessonWithNoAt = createAsyncThunk(
  `${sliceName}/updateSingleLesson`,
  async (lessonId: string, { getState, dispatch }) => {
    try {
      const response = await Api.get<LessonWithNoAT>(`/lessons/${lessonId}`);
      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'Could not fetch lesson details', dispatch);
      throw err;
    }
  },
);

export const setNewActionTaker = createAsyncThunk(
  `${sliceName}/setNewActionTaker`,
  async ({ lessonId, actionTakerId }: { lessonId: string; actionTakerId: string }, { dispatch }) => {
    try {
      // @ts-ignore
      const response = await Api.put<IActionTakerResponse>(`/lessons/${lessonId}/action-taker/${actionTakerId}`);
      return response.data;
    } catch (err) {
      handleApiError(err as AxiosError, 'We could not set new action taker', dispatch);

      throw err;
    }
  },
);

export const initialState: LessonsWithNoATState = {
  lessons: [],
  isLoading: false,
  isEditATLoading: false,
};

export const lessonsWithNoATReducer = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLessonWithNoAT: (state: LessonsWithNoATState, { payload }) => {
      state.lessons = [payload, ...state.lessons];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLessonsWithNoAT.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLessonsWithNoAT.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.lessons = payload;
      }
      state.isLoading = false;
    });
    builder.addCase(fetchLessonsWithNoAT.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(setNewActionTaker.pending, (state) => {
      state.isEditATLoading = true;
    });
    builder.addCase(setNewActionTaker.fulfilled, (state, { payload }) => {
      // Remove the lesson with the specified ID
      state.lessons = state.lessons.filter((lesson) => lesson.id !== payload.id);
      state.isEditATLoading = false;
    });
    builder.addCase(setNewActionTaker.rejected, (state) => {
      state.isEditATLoading = false;
    });

    builder.addCase(updateSingleLessonWithNoAt.pending, (state) => {
      state.isEditATLoading = true;
    });
    builder.addCase(updateSingleLessonWithNoAt.fulfilled, (state, { payload }) => {
      const index = state.lessons.findIndex((lesson) => lesson.id === payload.id);
      if (index !== -1) {
        state.lessons[index] = payload;
      } else {
        state.lessons.push(payload);
      }
      state.isEditATLoading = false;
    });
    builder.addCase(updateSingleLessonWithNoAt.rejected, (state) => {
      state.isEditATLoading = false;
    });
  },
});

export const getLessonsWithNoAT = (state: ReduxStoreType): LessonsWithNoATState => state[sliceName];
export const isEditATLoading = (state: ReduxStoreType): boolean => state[sliceName].isEditATLoading;

export default lessonsWithNoATReducer.reducer;
