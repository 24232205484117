import { combineReducers } from 'redux';

import attachments from './attachments';
import dictionary from './dictionary';
import filterSettings from './filterSettings';
import headerLoader from './headerLoader';
import infoElement from './infoElement';
import lessons from './lessons';
import lessonsWithNoAT from './lessonsWithNoActionTaker';
import lessonDetails from './lessonsDetails';
import messages from './messages';
import notifications from './notifications';
import notificationsSettings from './notificationsSettings';
import reports from './reports';
import structures from './structure';
import tags from './tags';
import user from './user';
import workshopDetails from './workshopDetails';
import workshopSpaces from './workshopSpace';
import workshopLessons from './workshopSpaceLessons';

const rootReducer = combineReducers({
  attachments,
  dictionary,
  filterSettings,
  headerLoader,
  infoElement,
  lessonDetails,
  lessons,
  lessonsWithNoAT,
  messages,
  notifications,
  notificationsSettings,
  reports,
  structures,
  tags,
  user,
  workshopDetails,
  workshopLessons,
  workshopSpaces,
});

export type ReduxStoreType = ReturnType<typeof rootReducer>;
export default rootReducer;
