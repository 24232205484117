import { callOnIdleTime } from '../../utils/functions';

import { dispatchToastEvent } from '../../events/helpers';
import { USER_GUIDE_OPTIONS } from '../Header';
import ToastMessage from '../Toast';
import './need-help.scss';

const NeedHelp = () => {
  const showHelperToast = () => {
    dispatchToastEvent(() => {}, undefined, 'left');
  };

  callOnIdleTime(showHelperToast);

  const renderHelpOptions = USER_GUIDE_OPTIONS.map((option, index) => (
    <div key={index} onClick={option.method} className="need-help-link">
      {option.label()}
    </div>
  ));

  return (
    <ToastMessage>
      <>{renderHelpOptions}</>
    </ToastMessage>
  );
};

export default NeedHelp;
