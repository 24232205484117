import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Formik, FormikHelpers } from 'formik';
import { object, string, number } from 'yup';

import { WorkshopSpace } from '../../../models/workshop';

import { WorkshopLessonInitialValues } from '../../../store/workshopDetails';
import {
  addLessonToWorkshop,
  clearCurrentLesson,
  editLessonInwWorkshop,
  getCurrentLessonInWorkshop,
} from '../../../store/workshopSpaceLessons';

import { useBoolean } from '../../../utils/hooks';

import { useAppDispatch } from '../../../root';
// components
import Drawer from '../../FlexDrawer';
import DrawerHeader from '../../FlexDrawer/Header';
import { TabIndex } from '../../LessonDetails';
import { VfTabs } from '../../vfDesign';
import { DocsTab, InfoTab } from './drawerTabs';

export interface WorkshopLessonProps {
  onClose: () => void;
  onOutsideClick: () => void;
  open: boolean;
  passedTab?: TabIndex;
  setPassedTab?: (tabIdx: number) => void;
  side: string;
  workshop: WorkshopSpace;
  workshopId: string;
}

const validationSchema = object().shape({
  positionInWorkshop: number().typeError('Must be a number'),
  priorityJustification: string().when('priority', {
    is: (val: string) => val === 'HIGH',
    then: string().required('Priority justification is required').max(2000, 'Priority justification can be 2000 chars at most'),
    otherwise: string().max(2000, 'Priority justification can be 2000 chars at most'),
  }),
  recommendation: string().max(2000, 'Recommendation can be 2000 chars at most'),
  title: string().required('Please add lesson title').max(255, 'Title can be 255 chars at most'),
});

const WorkshopLessonDrawer: FC<WorkshopLessonProps> = ({
  onClose,
  onOutsideClick,
  open = false,
  passedTab = TabIndex.INFO,
  setPassedTab,
  side,
  workshop,
  workshopId,
}) => {
  const dispatch = useAppDispatch();
  const { currentLesson, isEditMode } = useSelector(getCurrentLessonInWorkshop);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFullScreen, _, closeFullscreen, toggleFullscreen] = useBoolean(false);

  const handleClose = () => {
    onClose();
    closeFullscreen();
  };

  const initialValues: WorkshopLessonInitialValues = {
    actionTaker: isEditMode ? currentLesson.actionTaker : '',
    actionTakerId: isEditMode ? currentLesson.actionTaker?.id : '',
    creator: isEditMode ? currentLesson.creator : '',
    creatorId: isEditMode ? currentLesson.creator?.id : '',
    description: isEditMode ? currentLesson.description : '',
    filesUpload: [],
    organizationId: isEditMode ? currentLesson.organizationId : workshop.organizationId,
    origin: isEditMode ? currentLesson.origin : 'PROJECT',
    positionInWorkshop: isEditMode ? currentLesson.positionInWorkshop.toString() : '0',
    priority: isEditMode ? currentLesson.priority : 'MEDIUM',
    priorityJustification: isEditMode ? currentLesson.priorityJustification : '',
    processId: isEditMode ? currentLesson.processId : '',
    projectTypeId: isEditMode ? currentLesson.projectTypeId : workshop.projectTypeId,
    recommendation: isEditMode ? currentLesson.recommendation : '',
    tgPhase: isEditMode ? currentLesson.tgPhase : [],
    title: isEditMode ? currentLesson.title : '',
    workshopId: workshopId,
    workstreamId: isEditMode ? currentLesson.workstreamId : '',
  };

  const handleSubmit = async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
    if (isEditMode) {
      const payload = {
        id: currentLesson.id,
        body: { ...values, positionInWorkshop: +values.positionInWorkshop, tgPhase: values.tgPhase || null },
      };
      dispatch(editLessonInwWorkshop(payload));
    } else {
      dispatch(
        addLessonToWorkshop({
          ...values,
          tgPhase: values.tgPhase || null,
        }),
      );
    }
    resetForm();
    dispatch(clearCurrentLesson());
    handleClose();
  };

  const headerLabel = {
    editLesson: 'Edit lesson',
    createLesson: 'Create new lesson',
  };

  return (
    <Drawer
      delayTime={400}
      full={isFullScreen}
      isMounted={open}
      isOpen={open}
      onClose={handleClose}
      onOutsideClick={onOutsideClick}
      side={side}
    >
      <>
        <DrawerHeader
          headline={isEditMode ? headerLabel.editLesson : headerLabel.createLesson}
          isFullScreen={isFullScreen}
          isOpen={open}
          onClose={handleClose}
          onMaximize={toggleFullscreen}
        />

        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const formHasErrors = !formik.errors || Object.keys(formik.errors).length > 0;
              const userIsNotPermittedToEdit = isEditMode ? !currentLesson?.userRights?.canEditLesson : false;
              const isDisabled = formik.isSubmitting || formHasErrors || !formik.dirty || userIsNotPermittedToEdit;

              const tabs = [
                {
                  id: 0,
                  name: 'Info',
                  component: InfoTab,
                  props: {
                    currentLesson,
                    isDisabled,
                    isEditMode,
                    onClose: handleClose,
                    ...formik,
                  },
                },
                {
                  id: 1,
                  name: 'Files',
                  component: DocsTab,
                  props: {
                    userRight: currentLesson?.userRights?.canEditLesson,
                    lessonId: currentLesson?.id,
                    attachments: currentLesson?.attachments,
                    onClose: handleClose,
                    workshopId,
                    fromWorkshop: true,
                    ...formik,
                  },
                },
              ];
              return <VfTabs tabs={tabs} small passedTab={passedTab} setPassedTab={setPassedTab} />;
            }}
          </Formik>
        </>
      </>
    </Drawer>
  );
};

export default WorkshopLessonDrawer;
