import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form, FormikProps } from 'formik';

import { LessonDetails } from '../../../models/lessonDetails';

import { getDictionaryStates, getDoneOptions, getInProgressOptions } from '../../../store/dictionary';

import { LESSON_STATUS } from '../../../consts/lessons';
import AuthorComment from '../../AuthorComment';
import DrawerFooter from '../../FlexDrawer/Footer';
import Textarea from '../../Forms/Textarea';
import { VfButton, VfCalendar, VfCheckbox, VfDropdown } from '../../vfDesign';
import { TabIndex } from '../index';
import styles from './takeAction.module.scss';

interface TakeActionTabProps extends FormikProps<LessonDetails> {
  lessonDetails: LessonDetails;
  onClose: () => void;
  tabNameCallback: (tabName: TabIndex) => void;
}

const TakeActionTab: FC<TakeActionTabProps> = ({
  errors,
  isSubmitting,
  lessonDetails,
  onClose,
  resetForm,
  setFieldValue,
  setFieldTouched,
  tabNameCallback,
  values,
}) => {
  const progressOptions = useSelector(getInProgressOptions);
  const doneOptions = useSelector(getDoneOptions);
  const [userRights, setUserRights] = useState(false);
  const { data } = useSelector(getDictionaryStates);

  const statusOptions = data.map(({ label, id }) => ({
    label,
    value: id,
  }));

  useEffect(() => {
    tabNameCallback(TabIndex.TAKE_ACTION);
  }, [tabNameCallback]);

  useEffect(() => {
    setUserRights(lessonDetails?.userRights?.canTakeActionOnLesson);
  }, [lessonDetails]);

  const isClosed = values.state === LESSON_STATUS.DONE;
  const isInProgress = values.state === LESSON_STATUS.IN_PROGRESS;
  const isImprovementOpen = values.improvementDelivered;

  const getStatusDetailsOptions = () => {
    if (isClosed) {
      return doneOptions;
    }

    if (isInProgress) {
      return progressOptions;
    }

    return [];
  };

  const setCheckbox = (val: boolean) => {
    setFieldValue('improvementDelivered', val);
  };

  const setLessonStatus = (name: string, val: string) => {
    setFieldValue(name, val);
    setFieldValue('stateDetails', null);
  };

  const setDueDate = (name: string, date: any) => {
    const value = date ? date.toISOString() : '';

    setFieldValue(name, value);
  };

  const closeDrawer = () => {
    resetForm();
    onClose();
  };

  const formHasErrors = !errors || Object.keys(errors).length > 0;
  const isTakeActionDisabled = isSubmitting || formHasErrors || !userRights;
  const isDisabled = isSubmitting || !userRights;

  return (
    <Form>
      <div className={styles['take-action__container']}>
        <AuthorComment comment={values.additionalInformation} defaultExpanded={true} />

        <div className="mt-1 mb-1">
          <Textarea name="actionTakerAdditionalInformation" label="Action taker comment" disabled={isDisabled} />
        </div>

        <VfDropdown
          id="state"
          name="state"
          label="Status"
          disabled={isDisabled}
          value={values.state}
          options={statusOptions}
          onChange={setLessonStatus}
          onBlur={() => {}}
          fullWidth
          showFullLabelText
          showMinifiedLabel
        />

        <div className="mt-2 mb-2">
          <VfCalendar
            customPlaceholder="Due date"
            name="dueDate"
            setFieldTouched={setFieldTouched}
            setFieldValue={setDueDate}
            value={values.dueDate ? new Date(values.dueDate) : null}
          />
        </div>

        {(isInProgress || isClosed) && (
          <div className="mt-2 mb-2">
            <VfDropdown
              id="stateDetails"
              name="stateDetails"
              label="Status Details"
              disabled={isDisabled}
              value={values.stateDetails}
              options={getStatusDetailsOptions()}
              onChange={setFieldValue}
              onBlur={() => {}}
              fullWidth
              showFullLabelText
              showMinifiedLabel
            />
          </div>
        )}

        <div className="mt-1">
          <Textarea name="reasonDescription" label="Reason description" disabled={isDisabled} />
        </div>

        {isClosed && (
          <div className={styles['take-action__checkbox__container']}>
            <VfCheckbox
              id="improvementDelivered"
              disabled={isDisabled}
              onChange={() => setCheckbox(!values.improvementDelivered)}
              name="improvementDelivered"
              className={styles['takeAction__checkbox']}
              checked={values.improvementDelivered}
            />
            <span>Improvement delivered</span>
          </div>
        )}
        {isClosed && isImprovementOpen && (
          <>
            <div className="mt-2 mb-2">
              <Textarea name="improvementDescription" label="Add description" disabled={isDisabled} />
            </div>
            {values.workstreamName && (
              <div className={styles['take-action__improvement']}>
                Your improvement was implemented in workstream <span>{values.workstreamName}</span>
                {values.processName && (
                  <>
                    &nbsp;and in process <span>{values.processName}</span>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <DrawerFooter>
        <VfButton outline="secondary" onClick={closeDrawer}>
          Cancel
        </VfButton>
        <VfButton type="primary" htmlType="submit" disabled={isTakeActionDisabled}>
          Take action
        </VfButton>
      </DrawerFooter>
    </Form>
  );
};

export default TakeActionTab;
