import { Dispatch, RefObject } from 'react';

import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import { FormikValues } from 'formik';
import { AnyAction } from 'redux';

import { LessonDetails } from '../models/lessonDetails';
import { ADUser } from '../models/user';

import { setNotification } from '../store/notifications';

import { PROJECT_TYPE_ORDER } from '../components/NewLessonForm/steps/labels';
import { NOTIFICATION_TYPES } from '../components/vfDesign/vfNotification';
import { ONE_MINUTE } from '../consts';
import { FIELDS_NAMES_FOR_ID } from '../consts/lessons';
import { getToken } from '../services/auth';

const throttle = (callback: any, limit: number) => {
  var wait = false;
  return function () {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
    }
  };
};

const dashSpacedToLowerCase = (string: string): string => string.split(' ').join('_').toLowerCase();

const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

const dashSpacedToCapitalize = (string: string): string => {
  if (!string || typeof string !== 'string') {
    return '';
  }

  return string
    .split('_')
    .map((item) => capitalize(item))
    .join(' ');
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const content = (reader.result as string)?.split('base64,')[1];

      resolve({ fileName: file.path, content });
    };
    reader.onerror = (error) => reject(error);
  });

const prepHeaders = async (headers: Headers) => {
  const token = await getToken();

  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }

  return headers;
};

const getNameFromDisplayName = (displayName?: string) => {
  const names = displayName?.split(' ');
  return names ? names[1] : 'User';
};

const sortArrayOfObjects = (array: any[], fieldName: string, dir: 'ASC' | 'DESC' = 'ASC') => {
  if (!array.length) {
    return array;
  }

  const modifiers = {
    ASC: 1,
    DESC: -1,
  };

  return [...array].sort((a, b) => {
    const fields = fieldName.split('.');
    let fieldA = a;
    let fieldB = b;

    fields.forEach((field) => {
      fieldA = fieldA[field];
      fieldB = fieldB[field];
    });

    if (typeof fieldA === 'string' && typeof fieldB === 'string') {
      return modifiers[dir] * fieldA.localeCompare(fieldB);
    }

    if (dir === 'DESC') {
      return fieldB - fieldA;
    }

    return fieldA - fieldB;
  });
};

const sortLessonPreview = (a: any[], b: any[]) => {
  const aLabel: string = a[0];
  const bLabel: string = b[0];

  return PROJECT_TYPE_ORDER[aLabel] - PROJECT_TYPE_ORDER[bLabel];
};

const formatDate = (date: string): string => {
  return date ? format(Date.parse(date), 'RRRR-MM-dd') : '';
};

const handleFormikValueRelation = (fieldName: string, val: string | Partial<ADUser>, values: FormikValues) => {
  if (values && val && Object.keys(FIELDS_NAMES_FOR_ID).includes(fieldName)) {
    return values[FIELDS_NAMES_FOR_ID[fieldName]] || val;
  }

  return val;
};

const handleApiError = (
  err: AxiosError,
  defaultMsg: string,
  dispatch: Dispatch<AnyAction>,
  emitNotification = true,
) => {
  if (axios.isCancel(err)) {
    return;
  }

  let message = defaultMsg || 'Something went wrong';

  if (err?.response?.data?.message) {
    message = err.response.data.message;
  }

  if (emitNotification) {
    dispatch(setNotification(message, NOTIFICATION_TYPES.ERROR));
  }
};

const isMobile = (): boolean => {
  return window.innerWidth <= 410;
};

function removeEmptyFromObject<T>(body: T) {
  return Object.entries(body).reduce((acc: any, [key, val]: [string, string | boolean]) => {
    if (val) {
      acc[key] = val;
    }

    return acc;
  }, {});
}

const replaceFieldIdWIthName = (field: string): string => field.replace(/Id{0,2}$/, 'Name');
const scrollElementToTop = <T extends HTMLElement>(ref: RefObject<T>) => {
  setTimeout(() => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 100);
};

const nestedInObject = <T extends { [key: string]: any }>(object: T, fieldName: string) => {
  const nested = fieldName.split('.');
  let output = object;

  for (let x = 0; x < nested.length; x++) {
    output = output[nested[x]];
  }

  return output;
};

const unique = <T extends { [key: string]: any }>(arrayOfObj: T[], fieldName: string) => {
  return [...new Map(arrayOfObj.map((item: T) => [nestedInObject(item, fieldName), item])).values()];
};

const getProjectTypeName = (name: string) => {
  const nonstandardProjectsTypes: { [key: string]: string } = {
    OFFSHORE_PRODUCT_O_AND_M_IT: 'Offshore Product O&M IT',
    OFFSHORE_PRODUCT_O_AND_M: 'Offshore Product O&M',
    OFFSHORE_PRODUCT_SCADA: 'Offshore Product SCADA',
    OFFSHORE_PRODUCT_WTG: 'Offshore Product WTG',
  };

  return nonstandardProjectsTypes[name] || dashSpacedToCapitalize(name);
};

const upperCamelCaseToSpaced = (string: string) => {
  return string.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2').replace(/^./, (str) => {
    return str.toUpperCase().trim();
  });
};

const upperCamelCaseToDashed = (string: string) => {
  return string.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1_$2').replace(/^./, (str) => {
    return str.toUpperCase().trim();
  });
};

const callOnIdleTime = (cb: () => void, time = ONE_MINUTE) => {
  let timer: ReturnType<typeof setTimeout>;

  function resetTimer() {
    clearTimeout(timer);
    timer = setTimeout(cb, time);
  }

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onload = resetTimer;
  document.addEventListener('scroll', resetTimer, true);
};

const updateLessonDetailsFields = (lesson: Partial<LessonDetails>): Partial<LessonDetails> => {
  const lessonToUpdate = { ...lesson, tgPhase: lesson.tgPhase || null };

  delete lessonToUpdate.filesUpload;
  if (!lesson?.actionTakerDeputy?.id) {
    delete lessonToUpdate.actionTakerDeputy;
  }

  return lessonToUpdate;
};

const convertSearchQueryToObject = (searchQuery: string): Record<string, string> => {
  try {
    if (!searchQuery) {
      return {};
    }

    const params = new URLSearchParams(searchQuery);

    return Object.fromEntries(params);
  } catch (e) {
    console.log(e);
    return {};
  }
};

const mergeObjectsBasedOnProps = <T extends { [key: string]: string | string[] }>(objA: T, objB: Partial<T>): T => {
  const base: any = { ...objA }; // TODO: handle any

  for (const property in base) {
    if (objB.hasOwnProperty(property) && objB[property] !== '') {
      base[property] = objB[property];
    }
  }

  return base as T;
};

export {
  callOnIdleTime,
  capitalize,
  convertSearchQueryToObject,
  dashSpacedToCapitalize,
  dashSpacedToLowerCase,
  formatBytes,
  formatDate,
  getBase64,
  getNameFromDisplayName,
  getProjectTypeName,
  handleApiError,
  handleFormikValueRelation,
  isMobile,
  mergeObjectsBasedOnProps,
  prepHeaders,
  removeEmptyFromObject,
  replaceFieldIdWIthName,
  scrollElementToTop,
  sortArrayOfObjects,
  sortLessonPreview,
  throttle,
  unique,
  updateLessonDetailsFields,
  upperCamelCaseToDashed,
  upperCamelCaseToSpaced,
};
